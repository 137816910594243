<template>
  <div>
    <ui-transition>
      <div v-if="!isLoading" class="divide-y divide-cool-gray-800">
        <div v-for="item in items" :key="item.id" class="px-3 py-3 flex items-center">
          <div class="text-main text-sm flex-grow">
            {{ item.name }}
          </div>
          <div class="flex-shrink-0">
            <button class="w-4 text-right focus:outline-none" @click="edit(item.id, item.name)">
              <font-awesome-icon class="text-sub text-sm" :icon="['fas', 'pen']" />
            </button>
          </div>
        </div>
      </div>
    </ui-transition>
    <ui-dialog v-model="editDialog" class="z-30" :title="editedItem.id ? 'マスタ編集' : '新規作成'">
      <template #content>
        <div class="text-left">
          <ui-input v-model="editedItem.name" label="名称" placeholder="" :required="true" />
        </div>
      </template>
      <ui-button
        color="primary"
        :loading="updating"
        :disabled="disabled"
        class="mb-2"
        @click="save"
      >
        {{ editedItem.id ? '保存' : '登録' }}
      </ui-button>
      <ui-button class="mb-2" @click="editDialog = false">
        キャンセル
      </ui-button>
      <button
        v-if="editedItem.id"
        class="block focus:outline-none py-3 px-6 mt-2 mx-auto text-xs text-red-400"
        @click="deleteConfirmDialog = true"
      >
        このマスタを削除する
      </button>
    </ui-dialog>
    <ui-dialog
      v-model="deleteConfirmDialog"
      title="削除確認"
      type="error"
      :description="`マスタ「${editedItem.name}」を削除してもよろしいですか？`"
      class="z-40"
    >
      <ui-button color="error" :loading="deleting" class="mb-2" @click="remove">
        削除する
      </ui-button>
      <ui-button
        @click="
          deleteConfirmDialog = false
          editedItem.id = 0
          editedItem.name = ''
        "
      >
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import PropertyService from '../services/PropertyService'

export default {
  name: 'PropertyEdit',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    items: [],
    isLoading: true,
    updating: false,
    deleting: false,
    editedItem: {
      id: 0,
      name: '',
    },
    editDialog: false,
    deleteConfirmDialog: false,
  }),
  computed: {
    path() {
      return this.name.split('-').join('/')
    },
    disabled() {
      return !this.editedItem.name
    },
  },
  watch: {
    name() {
      this.fetch()
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      this.items = await PropertyService.get(this.path)
      this.isLoading = false
    },
    async silentFetch() {
      this.items = await PropertyService.get(this.path)
    },
    create() {
      this.editedItem.id = 0
      this.editedItem.name = ''
      this.editDialog = true
    },
    edit(id, name) {
      this.editedItem.id = id
      this.editedItem.name = name
      this.editDialog = true
    },
    async save() {
      this.updating = true
      if (!this.editedItem.id) {
        await PropertyService.create(this.path, { ...this.editedItem })
      } else {
        await PropertyService.update(this.path, { ...this.editedItem })
      }
      this.editDialog = false
      this.editedItem.id = 0
      this.editedItem.name = ''
      this.updating = false
      this.silentFetch()
    },
    async remove() {
      try {
        this.editDialog = false
        this.deleting = true
        await PropertyService.delete(this.path, this.editedItem.id)
      } catch {
        this.$store.dispatch('notification/set', {
          type: 'error',
          message: '他のアイテムに参照されているため、削除できませんでした',
        })
      } finally {
        this.deleteConfirmDialog = false
        this.editedItem.id = 0
        this.editedItem.name = ''
        this.deleting = false
        this.silentFetch()
      }
    },
  },
}
</script>
