import axios from 'axios';

export default {
  async get(path) {
    const resp = await axios.get(`/api/${path}`);
    return resp.data;
  },
  async create(path, item) {
    const resp = await axios.post(`/api/${path}`, item);
    return resp.data;
  },
  async update(path, item) {
    const resp = await axios.put(`/api/${path}/${item.id}`, { name: item.name });
    return resp.data;
  },
  async delete(path, id) {
    const resp = await axios.delete(`/api/${path}/${id}`);
    return resp.data;
  },
};
